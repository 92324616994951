.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
}

input {
  box-shadow:none !important;
}

#bottom {
  overflow-y:scroll;
  max-height:300px;
  margin-bottom:20px !important;
}

.history {
	/* height: calc(50vh - 75px); */
  width:100%;
	height:405px;
	padding:0;
	overflow-y: scroll;
}
