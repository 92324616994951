
table.striped>tbody>tr:nth-child(odd) {
  background-color: rgb(37, 37, 37) !important;
}

tspan {
	font-size: 0.85rem;
}

.modal {
	max-width: 1000px;
  max-height: 90%;
  background-color: #fffbfa;
}

.modal-footer{
  background-color: #fffbfa !important;
}

.btn{
	border-radius: 1px !important;
}

#searchMarkets{
	padding-left:22px;
	opacity:0.8;
	color:white;
	height:20px !important;
	width:calc(100% - 25px)
}

.section {
	padding:0 !important;
	margin-left: 1px;
	margin-right: 1px;
	background:rgb(10,10,10);
	border:2px solid #262b3e;
}

.choosedate{
	font-size:.8rem;
	color:grey;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

#test-slider{
	margin-top:10px;
	margin-bottom:20px;
	width:80%;
	left: 10%;
}



horizontal .noUi-handle {
	zoom: 0.9;
}


.matched > td {
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 5px;
}


.start-bc {
	position: fixed;
	float: left;
	padding: 0px;
	padding-top: 19px;
	margin: 0px;
	left: 60px;
	font-size: 20px;
}

.shorter {
	width:120px;
}

.amcharts-chart-div a {
	display: none !important;
}

.stroke {
	text-shadow: 1px 2px black
}

.safemode {
	color: #f79696;
	font-family: Gilmer-Light;
	height:61px;
	line-height: 61px;
	font-size:1.3rem;
}

@media only screen and (min-width: 993px) {
	.container {
			width: 90%;
}
}

.s20{
	width: 20%;
	margin-left: auto;
	left: auto;
	right: auto;
}

g[opacity="0.4"] {
	display:none;
}

g[opacity="0.3"] {
	display:none;
}

.minWidth {
	min-width: 1280px;
}

.pricediv {
	width: 100%;
	height: 300px;
}

.axis--x path {
display: none;
}

.axis--y path {
display: none;
}

.red {
background-color: rgb(222, 114, 114);
color: white;
}

.fadein {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
		 -moz-animation: fadein 1s; /* Firefox < 16 */
			-ms-animation: fadein 1s; /* Internet Explorer */
			 -o-animation: fadein 1s; /* Opera < 12.1 */
					animation: fadein 1s;
}

@keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from { opacity: 0; }
	to   { opacity: 1; }
}

.fadeout {
	-webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
		 -moz-animation: fadeout 1s; /* Firefox < 16 */
			-ms-animation: fadeout 1s; /* Internet Explorer */
			 -o-animation: fadeout 1s; /* Opera < 12.1 */
					animation: fadeout 1s;
}

@keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
	from { opacity: 1; }
	to   { opacity: 0; }
}

.green {
background-color: rgb(96, 202, 96);
color: white;
}

.grey-text{
color:grey;
}

svg, html, body {
width: 100%;
height: 100%;
}

.bar.bid {
fill: rgba(40, 193, 44, 0.3);
}

.orderbook-visualisation-tooltip > table {
color:white;
}

.bar.ask {
fill: rgba(255, 0, 0, 0.4);
}

.tick{
color:white;
}

text {
color:white;
}

.blacktext {
color:black !important;
}

.bar:hover {
fill: #337ab7;
}

.axis--x path {
display: none;
}

.axis--y path {
display: none;
}


.drop-zone{
	position: relative;
	margin: 0 auto;
	padding: 10px;
	border-width: 2px;
	border-color: white;
	border-style: dashed;
	border-radius: 5px;
}


table.striped>tbody>tr:nth-child(odd) {
	background-color: rgb(74, 74, 74);
}

.addr {
	font-size: 0.8em !important;
}

.tabs .indicator {
	background-color: #12b6f4 !important;
}

.tabs .tab a {
	color: white;
}

.tabs .tab a:hover {
	color: grey;
}

.tabs .tab a.active {
	color: white;
}

.tabs {
	background: none !important;
}

td {
	/* word-wrap: break-word; */
}
td, th {
	padding: 2px 5px;
	font-size:10px;
}

tr {
	height:20px !important;
}

.orders td {
	lineHeight: 30px;
	padding: 1px;
}

td a {
		display:block;
		width:100%;
		color:white;
}


#agree {
	opacity:1 !important;
	position:inherit !important;
	pointer-events: fill !important;
}

#agree_checkbox {
	position:inherit !important;
}

@media only screen and (max-width: 600px) {
	#investorText {
		display:none;
	}
	#portalText {
		display:none;
	}
}

#investorText{
	color:white;
	height:61px;
	line-height: 61px;
	padding-left:5px;
	font-size:20px;
}

input {
	height: 2.5rem !important;
	font-size: 12px !important;
}

.s12 {
  padding:0 !important;
	margin-bottom: 0px !important;
}

.row{
  margin-bottom: 0px !important;
}

h1,h2,h3,h4,h5,h6,p,input {
	/* color:white !important; */
}

h6{
  font-size:0.8rem;
}

#miner{
	margin-top:10px;
}

html {
	position: relative;
	min-height: 100%;
	font-family:Gilmer;
	/* font-family: Gilmer-Light; */
	/* font-family: 'Roboto', Georgia, sans-serif; */
	/* font-family: Roboto,Georgia,sans-serif; */
}

.editiong {
	padding-left:8px !important;
	padding-right:8px !important;
}

.react-grid-Cell input.editor-main, select.editor-main{
	padding:0px !important;
	height:35px !important;
	border-radius: 0px !important;
	margin-left: 0px !important;
	border-left: none !important;
	border-top: none !important;
	border-right: none !important;
	border-bottom: none !important;
	padding-left:5px !important;
	line-height:35px !important;
}

.drag-handle{
	display:none !important;
}

.react-grid-Cell__value div span div{
	height:35px;
	line-height: 35px !important;
}

.react-grid-Toolbar{
	margin-bottom:20px !important;
	border:none !important;
}
.react-grid-Main {
	outline: none !important;
}
.react-grid-Grid {
	border:none !important;
}

.react-grid-Cell__value {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@media only screen and (max-width: 1279px) {
.hide-on-med-and-down {
		display: none !important;
}
}


.tr-white {
	border-bottom:1px solid white !important;
}

.tr-grey {
	border-bottom:1px solid grey !important;
}

.tabs .tab a {
	text-overflow: clip !important;
}

.tr-none {
	border-bottom:none !important;
}

.order-book tr {
	border-bottom: none !important;
}

.divider {
	margin: 0px !important;
}

.red {
	background-color: #ee6e73
}

.trpContentPanel {
	background-color: #101535 !important;
}

body {
	background-color: black !important;
	text-color:white;
}

.text-grey {
	color: #9199a1 !important;
}
