.assets{
	border-right:1px solid grey;
	padding:0;
}

.material-tooltip {
	font-size:0.8rem;
}

.passportTop{
	padding-top:20px;
	position:absolute;
	right:calc(50% - 575px);
}

@media only screen and (max-width: 1280px) {
	.passportTop{
		padding-top:20px;
		position:absolute;
		left: calc(640px - 575px);
	}
}

.passportDiv{
	background:rgb(10,10,10);
	border:1px solid grey;
	zoom:0.9;
}

.transfers{
	/* height: calc(50vh - 75px); */
	padding:0;
}

.svg100 svg {
	height:100px !important;
}

.svg140 svg {
	height:140px !important;
}

.startinput {
	color:white;
}

.assetLogo{
  height:25px;
  margin-top:10px;
  margin-left:10px;
  float:left;
}



.transferLogo{
	height: 5em;
  opacity: 0.7;
  margin-top: 120px;
}

.historyLogo{
	height: 8em;
  opacity: 0.7;
  margin-top: 40px;
}

.feeHelper{
	padding-left: 14px;
	cursor: pointer;
  margin: 0;
  float: right;
}

.balanceHelper{
	cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: left;
}


.waves-effect {
  -webkit-transition: .1s ease-out;
  transition: .1s ease-out;
}

#terms > h4 {
  font-size: 1.2em;
}

#terms > h5 {
  font-size: 1em;
}

#terms > ul {
  padding-left: 30px;
}

#terms > ul > li {
  margin:7px;
}

#terms > p, #terms > ul > li {
  font-size: 0.8em;
  line-height: 1.4em;
  list-style-type: square;
}
.searchIcon {
  color:white;
  position:absolute;
  font-size:18px;
  margin-top:3px;
  margin-left:2px;
}

.marketItem{
  line-height:22px;
  font-size:11px;
  padding:1px 5px;
}

.marketType{
  margin:0;
  padding:0;
  line-height:25px;
  cursor:pointer;
}

.marketTable {
  table-layout:fixed;
  font-size:1.2rem;
  line-height:21px;
}

.historybook {
  height:288px;
  max-height:288px;
  overflow-y:scroll;
}

.marketPicker{
  padding:0 !important;
  overflow-y:scroll;
  height:368px;
}

input {
 outline:none;
}

.tradeHistoryTitle{
  margin-top:10px;
  margin-left:2px;
  margin-bottom:0px;
  color:white;
}
#searchMarkets:hover{
  border:none;
  outline-width: 0;
  outline:none;
}
#searchMarkets:focus{
  box-shadow:none;
  border:none;
  outline-width: 0;
  outline:none;
}

#searchMarkets{
	padding-left:22px;
	opacity:0.8;
	color:white;
	height:20px !important;
	width:calc(100% - 22px);
  margin:0;
  border:none;
  margin-bottom:2px;
}

#placeOrderBal{
	color:white;
	padding-right:0;
	font-size: 11px;
	opacity:1;
}


#placeOrderNums{
	color:white;
	font-size: 11px;
	opacity:1;
}

#placeOrderNums p{
	cursor:pointer;
	font-size: 9px;
	border:1px solid grey;
	color:grey;
	text-align: center;
}

#placeOrderNums p:hover{
	border:1px solid white;
	color:white;
}

#placeOrderNums .s3{
	padding:2px;
}

.tabs {
	height:30px;
	line-height: 30px;
	margin-bottom: 10px;
}

.tabs > .tab {
	height:30px;
	line-height: 30px;
}

.placeImg2{
	margin-top:2px;
	margin-right:5px;
	float:right;
	height:25px;
}

.placeImg{
	margin-top:2px;
	margin-left:5px;
	float:right;
	height:25px;
}

.placeArrow{
	color: white;
	float: right;
	margin-top:8px;
	font-size: 15px;
}

.confirmationCurrencies{
	width: 110px;
  float: right;
	margin-top:3px;
  padding-top: 3px;
  padding-right: 7px;
  height: 40px;
  border: 2px solid black;
}
