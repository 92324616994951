tspan {
  font-size:0.9rem !important;
}

text{
  /* transform: translate(5px, 2px); */
}

html,
body {
  height: 100%;
}
.bottom {
  bottom: 5px;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
}

.borderlessMain {
  font-family: Gilmer;
  top: 22%;
  text-align: center;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 5vh;
}

.borderlessMain2 {
  font-family: Gilmer;
  top: 30%;
  text-align: center;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 1.5vh;
}

.startbtn{
  background:white;
  color:black;
  font-family: Gilmer;
  font-size:2vh;
  height:4.5vh !important;
  line-height: 4.5vh;
}

.input-field>label {
  font-size:1.5vh !important;
  transform: translateY(2px);
}

.input-field>label:not(.label-icon).active {
  -webkit-transform: translateY(-2vh) scale(0.8);
  transform: translateY(-2vh) scale(0.8);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.startinput{
  font-size:1.5vh !important;
  height:3vh !important;
  line-height: 3vh !important;
  margin:0 !important;
}

.borderless3 {
  font-family: Gilmer;
  top: 40%;
  left:15%;
  width:70%;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-weight: 400;
  font-size: 3vh;
  z-index:100;
}

.borderless2 {
  font-family: Gilmer;
  top: 50%;
  left:15%;
  width:70%;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  font-weight: 400;
  font-size: 3vh;
  z-index:100;
}

.borderless2  h5 {
  font-weight: 400;
  font-size: 2.5vh;
  cursor:pointer;
  z-index:1000;
}

.borderless3  h5 {
  font-weight: 400;
  font-size: 2.5vh;
  cursor:pointer;
  z-index:1000;
}

.textinput2 {
  font-family: Gilmer;
  top: 53%;
  text-align: center;
  position: absolute;
  width: 38%;
  left: 31%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2vh;
  padding: 3% 0%;
  border: 1px dashed white;
}

.textinput3 {
  font-family: Gilmer;
  top: 53%;
  text-align: center;
  position: absolute;
  width: 30%;
  left: 35%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2.5vh;
  padding: 3% 0%;
  border: 1px dashed white;
}

.textinput {
  font-family: Gilmer;
  top: 62%;
  text-align: center;
  position: absolute;
  width: 35%;
  left: 32.5%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2vh;
  padding: 3% 0%;
  border: 1px dashed white;
}

.enterInput {
  font-family: Gilmer;
  top: 55%;
  text-align: center;
  position: absolute;
  width: 45%;
  left: 27.5%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 2.5vh;
  padding: 5% 0%;
  z-index:15;
}


.blockchains {
  font-family: Gilmer;
  bottom: 3.5%;
  text-align: center;
  position: absolute;
  width: 100%;
  margin: 0 auto;
  font-size: 2.5vh;
}
.block {
  bottom: 14%;
  left: calc(50% - 25px);
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid white;
}
.block2 {
  bottom: 14%;
  left: calc(50% - 5px);
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid white;
}
.block3 {
  bottom: 14%;
  left: calc(50% + 15px);
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid white;
}
.line {
  bottom: calc(14% + 5px);
  left: calc(50% - 12px);
  position: absolute;
  width: 8px;
  height: 1px;
  background: white;
}
.line2 {
  bottom: calc(14% + 5px);
  left: calc(50% + 8px);
  position: absolute;
  width: 8px;
  height: 1px;
  background: white;
}
.contenedor {
  color: #dcdce2;
  position: fixed;
  top: calc(50% - 40vh);
  left: calc(50% - 40vh);
  width: 80vh;
  height: 80vh;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}
.megarotate {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(30deg);
  z-index: 10;
}
.rotate {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  transform: rotate(60deg);
  z-index: 10;
}
.rotateT {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(120deg);
  z-index: 10;
}
.rotate3 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  transform: rotate(180deg);
}
.rotate4 {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(240deg);
  z-index: 10;
}
.rotate5 {
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(300deg);
  z-index: 10;
}
.lineTop {
  position: absolute;
  top: 3PX;
  left: calc(50% - 1px);
  width: 2px;
  height: 10%;
  transform-origin: center center;
  color: white;
  background-color: white;
  opacity: 1;
  z-index: 10;
}
.borderUno {
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  z-index: -1;
  transform-origin: center center;
  animation: rotar 5s linear infinite;
}
.borderDos {
  border-radius: 100%;
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  animation: rotarIz 5s linear infinite;
  transform-origin: center center;
}
.borderUno,
.borderDos {
  box-shadow: inset 0 7.03125px 0 rgba(0, 250, 250, 0.8), inset 7.03125px 0 0 rgba(0, 200, 200, 0.8), inset 0 -7.03125px 0 rgba(50, 150, 200, 0.8), inset -7.03125px 0 0 rgba(50, 200, 250, 0.8);
  z-index: -1;
}
@keyframes rotar {
  0% {
    transform: rotateZ(0deg) scaleX(1) scaleY(1);
  }
  50% {
    transform: rotateZ(180deg) scaleX(0.99) scaleY(0.99);
  }
  100% {
    transform: rotateZ(360deg) scaleX(1) scaleY(1);
  }
}
@keyframes rotarIz {
  0% {
    transform: rotateZ(0deg) scaleX(1) scaleY(1);
  }
  50% {
    transform: rotateZ(-180deg) scaleX(0.99) scaleY(0.99);
  }
  100% {
    transform: rotateZ(-360deg) scaleX(1) scaleY(1);
  }
}
