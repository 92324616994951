#wallets {
  height:853px;
  overflow-y:scroll;
}

.hoverA {
	font-size: 15px;
	color:grey;
	cursor:pointer;
  padding:6px;
  border: 1px solid grey;
}

.addressHover{
  width: calc(100% - 1px) !important;
}

p {
  margin: 0;
}

.addr {
  fontSize:0.9rem;
  padding-left:10px;
  line-height:1.1rem;
}

.addressHover:hover{
  background:rgb(37, 37, 37);
}

.hoverA{
  font-size: 12px;
}

.hoverA:hover{
	color:white;
	cursor:pointer;
  border: 1px solid white;
}

.trash:hover {
	color:grey;
}

.trash {
	color:black;
	float:right;
	margin-top:5px;
	font-size:15px;
	margin-right:5px;
	z-index:100000;
}

.trashIcon {
	float:left;
	font-size:20px;
}

.trashText{
	font-size:12px;
}
