#closeModal {
    float: right;
    width: 24px;
    text-align: center;
    height: 24px;
    padding: 0;
}

input::placeholder {
  color:black;
  opacity:0.5;
  font-weight:bold;
}
