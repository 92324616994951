.topLink {
  font-family: Gilmer;
	line-height: 43px;
	font-size:0.9rem;
}

.zoomOver:hover{
  transform: scale(1.5)
}

.topLink:hover{
  text-decoration: underline;
}

.tutorialSection{
  margin:0;
  padding:0;
  font-size: 14px;
  line-height: 20px;
  text-align:left;
}

.tutorialSection2{
  font-family:Gilmer-Light;
  margin:0;
  padding:0;
  font-size: 11px;
  line-height: 22px;
  text-align:left;
  padding-left:10px;
  cursor:pointer;
}

.tutorialSection2:hover{
  background: rgb(0,0,0,0.05);
}

.rightLink:hover{
  text-decoration: underline;
}

.numImage {
  max-height:220px;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-size: 12px;
  letter-spacing:0.5px;
  font-weight:100;
  line-height: 20px;
  text-align:left;
  font-family: Founders;
}

.tutorialTitle{
  margin-top:-30px !important;
  margin-bottom:10px;
  font-size:20px;
  text-align: left;
}

.fixedTop{
  width:100%;
  min-width: 1280px;
  position: sticky;
  top: 0;
  z-index:10;
}

.childHeight{
  font-size: 11px;
  line-height: 30px;
  display: block;
  padding-top: 8px;
  padding-bottom:10px;
}

.childSend{
  border:1px solid grey;
  float:right;
  height:20px;
  margin-top:5px;
  color:grey;
  margin-right:10px;
  font-size: 15px;
}

.childSend:hover{
  border:1px solid grey;
  float:right;
  height:20px;
  margin-top:5px;
  margin-right:10px;
  color:white;
  font-size: 15px;
}


.sendText {
  float:left;
  line-height: 18px;
  font-size:12px;
  margin-right:5px;
}

.sendChildIcon{
  display: inline-block !important;
  line-height: 18px !important;
  height: 18px !important;
  font-size:15px !important;
}

.childBalance{
  font-size: 11px;
  line-height: 22px;
  display: block;
  margin-left:40px;
  padding-top:10px;
  padding-bottom:10px;
}

.childBalances{
  z-index:100000;
  border:1px solid grey;
  border-top:none;
  zoom:0.8;
  width:380px;
}


.download{
  font-size: 11px;
  text-align: center;
  line-height: 22px;
  display: block;
  padding-top:10px;
  padding-bottom:10px;
}

.downloads{
  z-index:100000;
  border:1px solid grey;
  border-top:none;
  zoom:1;
}

.childImg {
  float:left;
  margin-right:6px;
  margin-left:6px;
}

#modalSettings{
  width:460px;
  max-height:75%;
}

.settingsHelper{
  font-size: 10px;
}

.rightLink {
  font-family: Gilmer;
	line-height: 43px;
	font-size:0.8rem;
}

.borderless {
	font-family: Gilmer;
	height:43px;
	line-height: 43px;
	font-size:1.5rem;
  color:white;
}

.tradingview-widget-copyright{
  display:none;
}

#bcimg {
  margin-top:5px;
	height:33px;
	padding-left:15px;
}

#trading {
  width:460px;
  margin-left:-25px;
}

.icon {
  line-height: 43px !important;
  height:43px !important;
  color:white;
}

.navbar-fixed{
  height:43px;
  padding:0;
  margin:0;
	background: rgba(29, 29, 29, 1) !important;
}

.pending{
  color:#f79696
}

nav {
  height:43px;
}

nav ul a {
		font-size: 0.8rem;
		padding: 0 13px;
		color:#b6b6b7;
}

.fluid {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: Arial;
  line-height: 1.3;
  font-size: 16px;
}

.fluid__instructions {
  flex: 0 0 auto;
  margin: 0 20px;
}

.fixed__instructions {
  flex: 1;
  margin: 0 20px;
}

a {
  color: black;
}

a:hover {
  color: #666;
}

.code {
  font-family: Courier New, Courier, monospace;
}

@media (min-width: 480px) {
  .fluid {
    flex-direction: row;
  }

  .fluid__image-container{
    flex: 0 0 30%;
    margin: 20px 0 20px 20px;
  }

  .fluid__instructions {
    flex: 0 0 50%;
    padding-top: 30px;
  }

  .fixed__instructions {
    padding-top: 30px;
    margin: 0 10px;
  }

  .portal {
    position: absolute;
    top: 40px;
    left: -30px;
  }
}
